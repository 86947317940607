'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import { Button } from './_components/Button'

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  const refresh = (): void => {
    window.location.reload()
  }

  return (
    <div className='flex h-fit w-full flex-1 flex-col justify-center m-auto gap-2 sm:max-w-2xl'>
      <div className='flex flex-col items-center bg-white p-10 md:rounded-3xl bg-opacity-60 gap-4'>
        <div className='text-center space-y-2'>
          <h1 className='text-2xl'>Oops! Something went wrong</h1>
          <p>
            We apologize for the inconvenience. The error has been reported to
            the developers.
          </p>
          <Button color='orange' onClick={refresh}>
            Try again
          </Button>
        </div>
      </div>
    </div>
  )
}
